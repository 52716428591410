/* eslint-disable react/react-in-jsx-scope */
export default function About() {
  return (
  <>
    <h1>About</h1>
    <ul>
      <li>This solar calculater is based on the paper written by Arnd Reichert.</li>
      <li>Doctorate mechanical engineer</li>
      <li>Director Engineering at Siemens Energy</li>
      
       <a href="https://de.linkedin.com/in/arnd-reichert-91391ba8"> LinkedIn</a>

    </ul>

  </>
  );
}

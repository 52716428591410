/* eslint-disable react/react-in-jsx-scope */
import InputContext from "../elements/appContext";
import Input from "./Input";
import Output from "./Output";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useState, useMemo } from "react";

export default function LandingPage() {
  const initSettings = [
    {key:0, 'Coef. Name': 'Power 4', 'Coef. Value': -1.30E-08, 'Coef. Value Cosine rule': 2.10E-09, Angle: 90, Factor:0, Correlation:0.01},
    {key:1, 'Coef. Name': 'Power 3', 'Coef. Value': 1.35E-06, 'Coef. Value Cosine rule': 2.30E-07, Angle: 66, Factor:0.476, Correlation:0.41},
    {key:2, 'Coef. Name': 'Power 2', 'Coef. Value': -1.10E-04, 'Coef. Value Cosine rule': -1.61E-04, Angle: 57, Factor:0.594, Correlation:0.55},
    {key:3, 'Coef. Name': 'Power 1', 'Coef. Value': -2.84E-03, 'Coef. Value Cosine rule': 0.94E-05, Angle: 31, Factor:0.832, Correlation:0.86},
    {key:4, 'Coef. Name': 'Power 0', 'Coef. Value': 1, 'Coef. Value Cosine rule': 1, Angle: 0, Factor:1, Correlation:1},
  ];

  const [activeIndexes, setActiveIndexes] = useState([0,1]);
  const [input, setInput] = useState({
    settings: initSettings,
    minuteSunsetShift: 3.2,
    latitude: 51.3,
    longitude: 6.75,
    timeOfTheYear: 0,
    peakPowerPanels: 270,
    publicProviderSellPrice: 0.38,
    publicProviderBuyPrice: 0.11,
    pricePvPanels: 1131,
    storagePrice: 1041,
    numberOfPeople: 5,
    seasonalConsumption: 10000,
    specialEquipmentConsumption: 5500,
    numberOfElectricVehicles: 0,
    annualDrivingDistance: 12000,
    storageFuelConsumption: 17,
    storageCapacity: 30,
    fractionStorage: 0.76,
    inclinationEarth: 23.44,
    temperatureFactor: 245/270,
    efficiencyConverter: 0.98,
    maxPowerCorrection: 1.00,
    dailyEnergyCorrect: 0.75,
    baseConsumption: 2,
    consumptionPerPerson: 4,
    storageCorrelationCoefficientsPower1: -0.3746,
    storageCorrelationCoefficientsPower0: 0.656,
    pollutionElectricityProvider: 633,

    rooftop:
      [
        {key:0, 'orientation': 283.0, 'inclination': 42.0, "peakPowerPanels": 0.270, "numberOfPanels": 45},
        {key:1, 'orientation': 103.0, 'inclination': 42.0, "peakPowerPanels": 0.355, "numberOfPanels": 37},
      ],

  });

  const [output, setOutput] = useState(null);

  const providerValue = useMemo(() => ({input, setInput,output, setOutput}), [input, setInput,output, setOutput])

  return (
    <div className="grid-container">
      <div className="grid-content">
        <span>
          The following is tool to calculate required and actual solar output
          based on solar panel size and location factoring in the battery size
          electric car usage, seasonal temperature factor and more. Please adjust the 
          input values below:
        </span>
      </div>
      <div className="grid-content">
        <Accordion multiple
          activeIndex={activeIndexes}
          onTabChange={(e) => setActiveIndexes(e.index)}
          style={{width:"90vw"}}>
          <AccordionTab header="Input Values">
            <InputContext.Provider value={providerValue}>
              <Input />
            </InputContext.Provider>
          </AccordionTab>
          <AccordionTab header="Output Values">
          <InputContext.Provider value={providerValue}>
              <Output />
            </InputContext.Provider>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
}

/* eslint-disable react/react-in-jsx-scope */
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { useContext, useEffect, useState } from "react";
import { Column } from "primereact/column";
import 'primeicons/primeicons.css';
import InputContext from "../elements/appContext";
import { Dialog } from 'primereact/dialog';

function radians(degree) {
  return degree*Math.PI/180;
}

function degrees(radians) {
  return radians*180/Math.PI;
}

function dot(a, b) {
  return a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);
}

function range(n, step) {
  return Array(n+1).fill().map((_,a)=>a*step);
}

export default function Input() {
  const [action, setAction] = useState();
  const {input, setInput, output, setOutput} = useContext(InputContext);
  const STEPSIZE=0.5;
  const NUMBERSTEPS=24/STEPSIZE;

  const minuteSunsetShiftPerAzimuth = 3.2;
  const hoursOfTheDay = range(NUMBERSTEPS, STEPSIZE);
  const months = [
    {key: 0, name: "January", degree: (10+15)/365*360, cloudFactor:2.06963614099743},
    {key: 1, name: "February", degree: (10+31+15)/365*360, cloudFactor:1.62529086854318},
    {key: 2, name: "March", degree: (10+31+28+15)/365*360, cloudFactor:1.41926515562632},
    {key: 3, name: "April", degree: (10+31+28+31+15)/365*360, cloudFactor:1.26951473936189},
    {key: 4, name: "Mai", degree: (10+31+28+31+30+15)/365*360, cloudFactor:1.42663440955948},
    {key: 5, name: "June", degree: (10+31+28+31+30+31+15)/365*360, cloudFactor:1.39225227645805},
    {key: 6, name: "July", degree: (10+31+28+31+30+31+30+15)/365*360, cloudFactor:1.70176666799775},
    {key: 7, name: "August", degree: (10+31+28+31+30+31+30+31+15)/365*360, cloudFactor:1.61470866767128},
    {key: 8, name: "September", degree: (10+31+28+31+30+31+30+31+31+15)/365*360, cloudFactor:1.63691345723843},
    {key: 9, name: "October", degree: (10+31+28+31+30+31+30+31+31+30+15)/365*360, cloudFactor:2.18020928327332},
    {key: 10, name: "November", degree: (10+31+28+31+30+31+30+31+31+30+31+15)/365*360, cloudFactor:1.83325078699112},
    {key: 11, name: "December", degree: (10+31+28+31+30+31+30+31+31+30+31+30+15)/365*360, cloudFactor:1.981566984936},
  ];

  const refreshOutput = () => {
    let tempOutput={...output};
    Array(5).fill().map(()=>tempOutput={
      months,
      annualTurningAngle: input?.timeOfTheYear === 0 || input?.timeOfTheYear === 2 ? 90 : input?.timeOfTheYear === 1 ? 180 : 0,
      // shift in minutes
      shiftDayLightSavingTime: -1/24*360*(tempOutput?.annualTurningAngle>90?1:0)*(tempOutput?.annualTurningAngle<270?1:0),
      azimuthOfSun: (
        Math.acos(
          Math.min(Math.sin(radians(input?.inclinationEarth*Math.cos(radians(-tempOutput?.annualTurningAngle))))
          /Math.cos(radians(input?.latitude)),1)
        ))/Math.PI*180,
      shiftSunrise: (90-tempOutput?.azimuthOfSun)*minuteSunsetShiftPerAzimuth/60,
      sunriseTime: 6-((input?.longitude-15)/360*24+(90-tempOutput?.azimuthOfSun)*minuteSunsetShiftPerAzimuth/60)
        -(tempOutput?.shiftDayLightSavingTime/360*24),
      sunsetTime: 18-((input?.longitude-15)/360*24-(90-tempOutput?.azimuthOfSun)*minuteSunsetShiftPerAzimuth/60)
        -(tempOutput?.shiftDayLightSavingTime/360*24),
      lightVectorSun: [
          0,
          Math.cos(radians(input?.inclinationEarth*Math.cos(radians(tempOutput?.annualTurningAngle)))),
          Math.sin(radians(input?.inclinationEarth*Math.cos(radians(tempOutput?.annualTurningAngle))))
        ],
      locationTransferMatrix:[
        [-1,0,0],
        [
          0,
          -Math.cos(radians(90-input?.latitude)),
          Math.sin(radians(90-input?.latitude)),
        ],
        [
          0,
          -Math.sin(radians(90-input?.latitude)),
          -Math.cos(radians(90-input?.latitude)),
        ]
      ],
      roofTopVectors: Array.isArray(input?.rooftop)?input.rooftop.map((rooftop,prop) =>
        ({
          roofTopVectorInLocation:
          [
            -Math.sin(radians(rooftop?.orientation))*Math.sin(radians(rooftop?.inclination)),
            -Math.cos(radians(rooftop?.orientation))*Math.sin(radians(rooftop?.inclination)),
            -Math.cos(radians(rooftop?.inclination))
          ],
          roofGlobalMidnightCoordinates:
            Array.isArray(tempOutput?.locationTransferMatrix)
            ? tempOutput?.locationTransferMatrix.map((transferVector) =>
              (Array.isArray(tempOutput?.roofTopVectors[prop]?.roofTopVectorInLocation)
              && tempOutput.locationTransferMatrix.length===tempOutput.roofTopVectors[prop].roofTopVectorInLocation.length
              ? dot(tempOutput.roofTopVectors[prop].roofTopVectorInLocation,transferVector)
              : 0)
            ) : [],
          peakPower: input?.temperatureFactor*input?.efficiencyConverter*rooftop?.numberOfPanels*rooftop?.peakPowerPanels,
          powerRoof: hoursOfTheDay?.map((hour)=>
            {
              const angleOfSunAtHour = radians(
                ((hour/24)*360)
                + input?.longitude
                + tempOutput?.shiftDayLightSavingTime
                -15
              );
              const degreeVariable = 
                Array.isArray(tempOutput?.roofTopVectors) 
                  && Array.isArray(tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates) 
                  && Array.isArray(tempOutput?.lightVectorSun) 
                ? Math.min( 90, degrees(
                    Math.acos(
                      (
                        (Math.cos(angleOfSunAtHour)
                        * tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[0])
                        - (Math.sin(angleOfSunAtHour)
                        * tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[1])
                      ) * tempOutput?.lightVectorSun[0]
                      + (
                        (Math.sin(angleOfSunAtHour)
                        * tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[0])
                        + (Math.cos(angleOfSunAtHour)
                        * tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[1])
                      ) * tempOutput?.lightVectorSun[1]
                      + (tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[2]
                        * tempOutput?.lightVectorSun[2]
                      )
                    )
                  ))
                  : 0;
              return (
                Math.max(0,
                  (
                    Array.isArray(input?.settings) 
                      && input?.settings 
                      && degreeVariable 
                      ? (
                        (input?.settings[0]?.['Coef. Value']*Math.pow(degreeVariable, 4))
                        + (input?.settings[1]?.['Coef. Value'] * Math.pow(degreeVariable, 3))
                        + (input?.settings[2]?.['Coef. Value'] * Math.pow(degreeVariable, 2))
                        + (input?.settings[3]?.['Coef. Value'] * Math.pow(degreeVariable, 1))
                        + (input?.settings[4]?.['Coef. Value'])
                      ): 0
                  )
                  * (Array.isArray(tempOutput?.roofTopVectors) ? tempOutput?.roofTopVectors[prop]?.peakPower : 0)
                  * ((hour>tempOutput?.sunriseTime) ? 1 : 0 )
                  * ((hour<tempOutput?.sunsetTime) ? 1 : 0 )
                )
              );
            }
          ),
          roofMonth: Array.isArray(months) ? months.map((month, propMonth)=>{
            const shiftDayLightSavingTime= -1/24*360*(month.degree>90?1:0)*(month.degree<270?1:0);
            const azimuthOfSun = (
              Math.acos(
                Math.min(Math.sin(radians(input?.inclinationEarth*Math.cos(radians(-month.degree))))
                /Math.cos(radians(input?.latitude)),1)
              ))/Math.PI*180
            const sunriseTime = 6-((input?.longitude-15)/360*24+(90-azimuthOfSun)*minuteSunsetShiftPerAzimuth/60)
              -(shiftDayLightSavingTime/360*24);
            const sunsetTime = 18-((input?.longitude-15)/360*24-(90-azimuthOfSun)*minuteSunsetShiftPerAzimuth/60)
              -(shiftDayLightSavingTime/360*24);
            const lightVectorSun = [
              0,
              Math.cos(radians(input?.inclinationEarth*Math.cos(radians(month.degree)))),
              Math.sin(radians(input?.inclinationEarth*Math.cos(radians(month.degree))))
            ];
            return (
              hoursOfTheDay?.map((hourMonth)=>
              {
                const angleOfSunAtHourMonth = radians(
                  ((hourMonth/24)*360)
                  + input?.longitude
                  + shiftDayLightSavingTime
                  -15
                );
                const degreeVariableMonth = 
                  Array.isArray(tempOutput?.roofTopVectors) 
                    && Array.isArray(tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates) 
                    && Array.isArray(lightVectorSun) 
                  ? Math.min( 90, degrees(
                      Math.acos(
                        (
                          (Math.cos(angleOfSunAtHourMonth)
                          * tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[0])
                          - (Math.sin(angleOfSunAtHourMonth)
                          * tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[1])
                        ) * lightVectorSun[0]
                        + (
                          (Math.sin(angleOfSunAtHourMonth)
                          * tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[0])
                          + (Math.cos(angleOfSunAtHourMonth)
                          * tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[1])
                        ) * lightVectorSun[1]
                        + (tempOutput?.roofTopVectors[prop]?.roofGlobalMidnightCoordinates[2]
                          * lightVectorSun[2]
                        )
                      )
                    ))
                    : 0;
                return (
                  Math.max(0,
                    (
                      Array.isArray(input?.settings) 
                        && input?.settings 
                        && degreeVariableMonth 
                        ? (
                          (input?.settings[0]?.['Coef. Value']*Math.pow(degreeVariableMonth, 4))
                          + (input?.settings[1]?.['Coef. Value'] * Math.pow(degreeVariableMonth, 3))
                          + (input?.settings[2]?.['Coef. Value'] * Math.pow(degreeVariableMonth, 2))
                          + (input?.settings[3]?.['Coef. Value'] * Math.pow(degreeVariableMonth, 1))
                          + (input?.settings[4]?.['Coef. Value'])
                        ): 0
                    )
                    * (Array.isArray(tempOutput?.roofTopVectors) ? tempOutput?.roofTopVectors[prop]?.peakPower : 0)
                    * ((hourMonth>sunriseTime) ? 1 : 0 )
                    * ((hourMonth<sunsetTime) ? 1 : 0 )
                  )
                );
              }));
            
          }) : []
        })
      ):[],

      powerTotal:  
        Array.isArray(tempOutput?.roofTopVectors) && tempOutput?.roofTopVectors.reduce((total,roof)=>(
          Array.isArray(roof?.powerRoof)?roof.powerRoof.map((powerRoofHour,propHour)=>(total[propHour]+powerRoofHour)): []
        ),
        Array(hoursOfTheDay.length).fill(0)),

      energyTotal: 
        Array.isArray(tempOutput?.roofTopVectors) && tempOutput?.roofTopVectors.reduce((total,roof)=>(
          Array.isArray(roof?.powerRoof)?roof.powerRoof.map((powerRoofHour,propHour)=>(total[propHour]+powerRoofHour*STEPSIZE)): []
        ),
        Array(hoursOfTheDay.length).fill(0)),

      hoursOfTheDay,

      maxPower: 
        Array.isArray(tempOutput?.powerTotal) 
        && Array.isArray(input?.maxPowerCorrection)  
        && Math.max(...tempOutput?.powerTotal)/(input?.maxPowerCorrection),

      totalMonth: 
        Array.isArray(months) ? months.map((month) => (
          Array.isArray(tempOutput?.roofTopVectors) 
          ? tempOutput?.roofTopVectors.reduce((total,roof)=>(
              Array.isArray(roof?.roofMonth[month.key])?roof.roofMonth[month.key].map((powerRoofHour,propHour)=>
                (total[propHour]+powerRoofHour)
              ): []
            ),
            Array(hoursOfTheDay.length).fill(0))
          : []
        )) : [],
      
      energyMonthTotal: 
      Array.isArray(months) ? months.map((month) => (
        Array.isArray(tempOutput?.roofTopVectors) 
        ? tempOutput?.roofTopVectors.reduce((total,roof)=>(
            Array.isArray(roof?.roofMonth[month.key])?roof.roofMonth[month.key].map((powerRoofHour,propHour)=>
              (total[propHour]+powerRoofHour*STEPSIZE)
            ): []
          ),
          Array(hoursOfTheDay.length).fill(0))
        : []
      )) : [],
    
    });
    setOutput(tempOutput);
    console.log(tempOutput);
  };

  useEffect(()=>{
    refreshOutput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    refreshOutput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[input,setInput, setOutput])

  const cellEditor = (options) => {
    return numberEditor(options);
  };

  const settingEditor = (options) => {
    return(
      <InputNumber
        value={options.value}
        mode="decimal"
        onValueChange={(e) => options.editorCallback(
          (options.field==="angle")
          ? Math.min(e.value,360)
          : (options.field==="Factor" || options.field==="Correlation")? Math.min(e.value,1): e.value)}
      />
    );
  };

  const deleteRow = (rowData) => {
    return (
      <Button
        onClick={()=>setInput({...input, rooftop: Array.isArray(input?.rooftop)?input.rooftop.filter((roof)=>roof.key!==rowData.key):[]})}
        className="p-button-rounded p-button-outlined p-button-secondary"
        icon="pi pi-trash"
      />
    );
    }

  const columnsRooftop = [
    // eslint-disable-next-line no-undef
    {header:"#", body: (_,prop) => (prop?.rowIndex+1)},
    {field: "orientation", header:"Orientation of Roof [°]", editor: (options) => cellEditor(options)},
    {field: "inclination", header:"Rectangular inclination [°]", editor: (options) => cellEditor(options)},
    {field: "peakPowerPanels", header:"Peak power of panels [kw]", editor: (options) => cellEditor(options)},
    {field: "numberOfPanels", header:"Number of panels", editor: (options) => cellEditor(options)},
    {field: "delete", body: deleteRow},
  ];

  const columnSettings = [
    {header:"Coeff. Name", field: "Coef. Name"},
    {field: "Coef. Value", header:"Coef. Value", editor: (options) => settingEditor(options)},
    {field: "Coef. Value Cosine rule", header:"Coef. Value Cosine rule", editor: (options) => settingEditor(options)},
    {field: "Angle", header:"Angle", editor: (options) => settingEditor(options)},
    {field: "Factor", header:"Factor", editor: (options) => settingEditor(options)},
    {field: "Correlation", header:"Correlation", editor: (options) => settingEditor(options)}
  ]

  const numberEditor = (options) => {
    return(
      <InputNumber
        value={options.value || 0}
        mode="decimal"
        onValueChange={(e) => options.editorCallback(
          (options.field==="orientation"||options.field==="inclination")
          ? Math.min(e.value,360)
          : e.value)}
      />
    );
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    if (newValue)
        rowData[field] = newValue;
    else
        event.preventDefault();

};

  const actionDialog = (actionData) => {
    switch (actionData) {
      case "moreSetting":
        return (
          <Dialog
            header="Setting"
            visible={true}
            style={{ width: '90vw' }}
            label="Show"
            icon="pi pi-external-link"
            onHide={() => setAction(null)}
          >
            <div className="form-box" style={{ width: '80vw' }}>
              <label htmlFor="fractionStorage">Fraction storage:</label>
              <InputNumber
                id="fractionStorage"
                className="form-item"
                value={input ? 100*input.fractionStorage : 0}
                max={100}
                suffix="%"
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, fractionStorage: Math.min(e.value,100)/100 })}
              />
              <label htmlFor="inclinationEarth">Inclination of Earth to Sun:</label>
              <InputNumber
                id="inclinationEarth"
                className="form-item"
                value={input ? input.inclinationEarth : 0}
                max={360}
                suffix="°"
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, inclinationEarth: Math.min(e.value,360) })}
              />
              <label htmlFor="temperatureFactor">Temperature Factor:</label>
              <InputNumber
                id="temperatureFactor"
                className="form-item"
                value={input ? input.temperatureFactor*100 : 0}
                max={100}
                suffix="%"
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, temperatureFactor: Math.min(e.value,100)/100 })}
              />
              <label htmlFor="efficiencyConverter">Efficiency converter:</label>
              <InputNumber
                id="efficiencyConverter"
                className="form-item"
                value={input ? input.efficiencyConverter*100 : 0}
                max={100}
                suffix="%"
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, efficiencyConverter: Math.min(e.value,100)/100 })}
              />
              <label htmlFor="maxPowerCorrection">Max Power Correction:</label>
              <InputNumber
                id="maxPowerCorrection"
                className="form-item"
                value={input ? input.maxPowerCorrection : 0}
                max={1}
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, maxPowerCorrection: Math.min(e.value,1) })}
              />
              <label htmlFor="dailyEnergyCorrect">Efficiency converter:</label>
              <InputNumber
                id="dailyEnergyCorrect"
                className="form-item"
                value={input ? input.dailyEnergyCorrect : 0}
                max={1}
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, dailyEnergyCorrect: Math.min(e.value,1) })}
              />
              <label htmlFor="baseConsumption">Base Consumption:</label>
              <InputNumber
                id="baseConsumption"
                className="form-item"
                value={input ? input.baseConsumption : 0}
                suffix=" kWh/day"
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, baseConsumption: e.value })}
              />
              <label htmlFor="consumptionPerPerson">Consumption Per Person:</label>
              <InputNumber
                id="consumptionPerPerson"
                className="form-item"
                value={input ? input.consumptionPerPerson : 0}
                suffix=" kWh/day"
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, consumptionPerPerson: e.value })}
              />
              <label htmlFor="storageCorrelationCoefficientsPower1">Storage Correlation Coefficient Power 1:</label>
              <InputNumber
                id="storageCorrelationCoefficientsPower1"
                className="form-item"
                value={input ? input.storageCorrelationCoefficientsPower1 : 0}
                max={1}
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, storageCorrelationCoefficientsPower1: Math.min(e.value,1) })}
              />
              <label htmlFor="storageCorrelationCoefficientsPower0">Storage Correlation Coefficient Power 0:</label>
              <InputNumber
                id="storageCorrelationCoefficientsPower0"
                className="form-item"
                value={input ? input.storageCorrelationCoefficientsPower0 : 0}
                max={1}
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, storageCorrelationCoefficientsPower0: Math.min(e.value,1) })}
              />
               <label htmlFor="pollutionElectricityProvider">Pollution Electricity Provide:</label>
              <InputNumber
                id="pollutionElectricityProvider"
                className="form-item"
                value={input ? input.pollutionElectricityProvider : 0}
                suffix=" g_CO2 / kWh"
                mode="decimal"
                onValueChange={(e) => setInput({ ...input, pollutionElectricityProvider: e.value })}
              />
            </div>
            <div className="form-box" style={{ width: '80vw' }}>
              <DataTable
                value={Array.isArray(input?.settings) && input.settings}
                editMode="cell"
                className="editable-cells-table"
                responsiveLayout="scroll"
              >
                {columnSettings.map(({field,header,body, editor},key) => (
                  <Column
                    key={key}
                    field={field}
                    header={header}
                    body={body}
                    editor={editor}
                    onCellEditComplete={onCellEditComplete}
                  />
                ))}
              </DataTable>
            </div>
          </Dialog>
        );
      default: return null;
      }
  }

  return (
    <div className="form-container">
      <div className="form-box">
        <h3>Location:</h3>
        <label htmlFor="latitude">Latitude:</label>
        <InputNumber
          id="latitude"
          className="form-item"
          value={input ? input.latitude : 0}
          max={360}
          suffix="°"
          mode="decimal"
          onValueChange={(e) => setInput({ ...input, latitude: Math.min(e.value,360) })}
        />
        <label htmlFor="longitude">Longitude:</label>
        <InputNumber
          id="longitude"
          className="form-item"
          value={input ? input.longitude : 0}
          max={360}
          suffix="°"
          mode="decimal"
          onValueChange={(e) => setInput({ ...input, longitude: Math.min(e.value,360) })}
        />
      </div>
      <div className="form-box">
        <h3>Prices:</h3>
        <label htmlFor="publicProviderSellPrice">Electricity sold by public provider:</label>
        <InputNumber
          id="publicProviderSellPrice"
          className="form-item"
          value={input ? input.publicProviderSellPrice : 0}
          onValueChange={(e) => setInput({ ...input, publicProviderSellPrice: e.value })}
          mode="currency"
          currency="EUR"
          locale="de-DE"
        />
        <label htmlFor="publicProviderBuyPrice">Electricity bought by public provider:</label>
        <InputNumber

          id="publicProviderBuyPrice"
          className="form-item"
          value={input ? input.publicProviderBuyPrice : 0}
          onValueChange={(e) => setInput({ ...input, publicProviderBuyPrice: e.value })}
          mode="currency"
          currency="EUR"
          locale="de-DE"
        />
       <label htmlFor="publicProviderBuyPrice">Price storage battery:</label>
        <InputNumber
          id="publicProviderBuyPrice"
          className="form-item"
          value={input ? input.storagePrice : 0}
          onValueChange={(e) => setInput({ ...input, storagePrice: e.value })}
          mode="currency"
          currency="EUR"
          locale="de-DE"
        />
        <label htmlFor="pricePvPanels">Price PV Panels and converter:</label>
        <InputNumber
          id="pricePvPanels"
          className="form-item"
          value={input ? input.pricePvPanels : 0}
          onValueChange={(e) => setInput({ ...input, pricePvPanels: e.value })}
          mode="currency"
          currency="EUR"
          locale="de-DE"
        />
      </div>
      <div className="form-box">
        <h3>Consumption:</h3>
        <label htmlFor="numberOfPeople">People in house hold:</label>
        <InputNumber
          id="numberOfPeople"
          className="form-item"
          value={input ? input.numberOfPeople : 0}
          onValueChange={(e) => setInput({ ...input, numberOfPeople: e.value })}
        />
        <label htmlFor="seasonalConsumption" style={{width: '18rem'}}>Additional seasonal consumption (e.g. heat pump):</label>
        <InputNumber
          id="seasonalConsumption"
          className="form-item"
          value={input ? input.seasonalConsumption : 0}
          suffix=" kwh/a"
          onValueChange={(e) => setInput({ ...input, seasonalConsumption: e.value })}
        />
        <label htmlFor="specialEquipmentConsumption">Consumption special equipment:</label>
        <InputNumber
          id="specialEquipmentConsumption"
          className="form-item"
          suffix=" kwh/a"
          value={input ? input.specialEquipmentConsumption : 0}
          onValueChange={(e) => setInput({ ...input, specialEquipmentConsumption: e.value })}
        />
        <label htmlFor="numberOfElectricVehicles">Number of electric vehicles:</label>
        <InputNumber
          id="numberOfElectricVehicles"
          className="form-item"
          value={input ? input.numberOfElectricVehicles : 0}
          onValueChange={(e) => setInput({ ...input, numberOfElectricVehicles: e.value })}
        />
        <label htmlFor="annualDrivingDistance">Annual driving distance:</label>
        <InputNumber
          id="annualDrivingDistance"
          className="form-item"
          suffix=" km/a"
          value={input ? input.annualDrivingDistance : 0}
          onValueChange={(e) => setInput({ ...input, annualDrivingDistance: e.value })}
        />
        <label htmlFor="storageFuelConsumption">Storage fuel consumption:</label>
        <InputNumber
          id="storageFuelConsumption"
          className="form-item"
          suffix=" kwh/100km"
          value={input ? input.storageFuelConsumption : 0}
          onValueChange={(e) => setInput({ ...input, storageFuelConsumption: e.value })}
        />
      </div>
      <div className="form-box">
        <h3>Time of the year:</h3>
        <label htmlFor="timeOfTheYear">Season:</label>
        <Dropdown
          className="form-item"
          options={[
            {label: 'Spring', value: 0},
            {label: 'Summer', value: 1},
            {label: 'Fall', value: 2},
            {label: 'Winter', value: 3},
          ]}
          value={input ? input.timeOfTheYear : ""}
          onChange={(e) => setInput({ ...input, timeOfTheYear: e.value })}
        />
      </div>
      <div className="form-box">
        <h3>Storage Capacity:</h3>
        <InputNumber
          className="form-item"
          value={input ? input.storageCapacity : ""}
          suffix = " kWh"
          onChange={(e) => setInput({ ...input, text: e.value })}
        />
      </div>
      <Button
          className="p-button-secondary form-box"
          label="More Settings"
          onClick={() => setAction("moreSetting")}
        />
        {action==="moreSetting" && actionDialog(action)}
      <div className="form-box" style={{width: "80vw", overflow: "scroll" }}>
        <h3>Rooftop:</h3>
        <DataTable
          style={{width: '75vw',  }}
          value={Array.isArray(input?.rooftop) && input.rooftop}
          editMode="cell"
          className="editable-cells-table"
          responsiveLayout="scroll"
        >
          {columnsRooftop.map(({field,header,body, editor},key) => (
            <Column
              key={key}
              field={field}
              header={header}
              body={body}
              editor={editor}
              onCellEditComplete={onCellEditComplete}
            />
          ))}
        </DataTable>
        <Button
          className="p-button-rounded p-button-outlined"
          style={{"margin-bottom":"1rem"}}
          label="Add Roof"
          onClick={()=>setInput({...input, rooftop: Array.isArray(input?.rooftop)?[...input.rooftop,{key:input.rooftop.length+1}]:[{key:1}]})}
        />
      </div>
    </div>
  );
}

/* eslint-disable react/react-in-jsx-scope */
import { Link } from "react-router-dom";
import logo from "../logo.png";

export default function NavBar() {
  return (
    <nav className="nav">
      <Link to="/" className="site-title">
        <img className="logo" src={logo} alt="logo" />
        SolarCalc
      </Link>
      <ul>
        <li>
          <Link to="/paper">Paper</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </nav>
  );
}

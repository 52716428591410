import { useContext, useState } from "react";
import InputContext from "../elements/appContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { LineChart, Line, XAxis,YAxis,ResponsiveContainer,  CartesianGrid, Legend } from 'recharts';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';

function radians(degree) {
  return degree*Math.PI/180;
}

/* eslint-disable react/react-in-jsx-scope */
export default function Output() {
  const {input, output} = useContext(InputContext);
  const [action, setAction] = useState();

  const data =
    Array.isArray(output?.hoursOfTheDay)
      ? output?.hoursOfTheDay.map((hour,prop)=>{
        let dataHour = {key: prop, hours:hour, total: Array.isArray(output?.powerTotal) && output?.powerTotal[prop]}
        // eslint-disable-next-line array-callback-return
        output?.roofTopVectors?.map((roof,propRoof) =>
          {
            dataHour = {
              ...dataHour,[`roof${propRoof}`]: Array.isArray(roof?.powerRoof) && roof?.powerRoof[prop]
            };
          }
        );
        return dataHour;
      })
      :[];
  
  const dataAngle =
    Array.isArray(input?.settings)
      ? input?.settings.map((setting,prop)=>
        ({
          key: prop, 
          angle:setting.Angle,
          cosineRule: Math.cos(radians(setting.Angle)),
          coefficient: setting.Factor        
      })
      ).reverse()
      :[];

  const columnsAnalysis = [
    {header:"Coeff. Name", field: "Coef. Name"},
    {field: "Coef. Value", header:"Coef. Value"},
    {field: "Coef. Value Cosine rule"},
    {field: "Angle", header:"Angle"},
    {field: "Factor", header:"Factor"},
    {field: "Correlation", header:"Correlation"}
  ];

  const actionDialog = (actionData) => {
    switch (actionData) {
      case "analysis":
        return (
          <Dialog
            header="Setting"
            visible={true}
            style={{ width: '90vw' }}
            label="Show"
            icon="pi pi-external-link"
            onHide={() => setAction(null)}
          >
            <div className="form-box" style={{ width: '80vw' }}>
              <DataTable
                value={Array.isArray(input?.settings) && input.settings}
                editMode="cell"
                className="editable-cells-table"
                responsiveLayout="scroll"
              >
                {columnsAnalysis.map(({field,header,body},key) => (
                  <Column
                    key={key}
                    field={field}
                    header={header}
                    body={body}
                  />
                ))}
              </DataTable>
            </div>
          </Dialog>
        );
      default: return null;
      }
  };

  const monthsData = output?.months.map((month)=>{
    const maxGen = Array.isArray(output?.energyMonthTotal) 
      && output.energyMonthTotal[month.key].reduce((total,current) => total+current);
    

    return({
      month: month.name,
      maxGen,
      factor: month.cloudFactor,
      gen: month.cloudFactor!==0 && maxGen/month.cloudFactor,
      // consumption: 
    });
  })

  console.log(monthsData);

  const columnsCorrelation = [
    {header: "Month", field:"month"},
    {header: "Max Gen", field:"maxGen"},
    {header: "Cloud & Shade Factor", field:"factor"},
    {header: "Generation", field:"gen"},
    {header: "No Storage", field:"noStorage"},
    {header: "Total Consumption", field:"totalConsumption"},
    {header: "Consumption", field:"consumption"},
    {header: "Electric Vehicles", field:"electricVehicles"},
    {header: "Export Predicted", field:"export"},
    {header: "Import", field:"import"},
  ];

  return (
    <div className="form-container">
      <div className="form-box" style={{width: "80vw"}}>
        <h3>Power over time:</h3>
        <div className="graph-container" >
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="hours" label={{ value: 'Day time [h]', position: 'bottom'}} />
              <YAxis label={{ value: 'Power [w]', angle: -90, position: 'insideLeft' }}/>
              <Legend layout="horizontal" verticalAlign="top" align="center" />
              <Line name="Total" type="monotone" dataKey="total" stroke="#8884d8" />
              {Array.isArray(output?.roofTopVectors) && output?.roofTopVectors.map((roof, prop) =>
                (<Line name={`Roof ${prop+1}`} type="monotone" dataKey={`roof${prop}`} stroke="#82ca9d" />)
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="form-box"  style={{width: "80vw"}}>
        <h3>Radiation Angle Factor:</h3>
        <div className="graph-container">
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart
              width={500}
              height={300}
              data={dataAngle}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="angle" label={{ value: 'Radiation Angle [°]', position: 'bottom'}} />
              <YAxis label={{ value: 'Angle Factor [-]', angle: -90, position: 'insideLeft' }}/>
              <Legend layout="horizontal" verticalAlign="top" align="center"/>
              <Line type="monotone" name='Factors' dataKey="coefficient" stroke="#8884d8" />
              <Line type="monotone" name='Cosine Rule Factor' dataKey="cosineRule" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="form-box">
          <ul className="form-item" style={{listStyleType: "none"}}>
            <li>Max power:  {output?.maxPower} kW</li>
            <li>Max Daily Energy: 11 kW</li>
            <li>Electricity Consumption: {input?.electricityConsumption} kWh/annum</li>
            <li>CO2 Pollution avoidance: {input?.co2PollutionAvoidance} tons of CO2/annum</li>
            <DataTable
              value={output?.roofTopVectors}
              size="small"
            >
              <Column header="Roof" body={(_,prop) => (prop?.rowIndex+1)}/>
              <Column header="Peak Power [kW]" field="peakPower"/>
            </DataTable>
          </ul>
      </div>
      <div className="form-box">
          <ul className="form-item" style={{listStyleType: "none"}}>
            <li>Installed panel capacity:  {output?.installedPanelCapacity} kWp</li>
            <li>Panel & converter costs: {output?.panelConverterCosts} €</li>
            <li>Storage costs: {input?.storageCosts} €</li>
            <li>Total cost: {input?.toalCosts} €</li>
            <li>Amortization time: {input?.amortizationTime} a</li>
          </ul>
      </div>
      <div className="form-box">
          <ul className="form-item" style={{listStyleType: "none"}}>
            <li>Import: &emsp; 4763 kWh &emsp; {input?.import} €/a</li>
            <li>Saving: &emsp; 12535 kWh &emsp; {input?.savings} €/a</li>
            <li>Export: &emsp; 6730 kWh &emsp; {input?.export} €/a</li>
            <li>Total: &emsp; &emsp; &emsp; &emsp;&emsp;&emsp;{input?.total} €/a</li>
          </ul>
      </div>
      <div className="form-box"  style={{width: "80vw", overflow: "scroll"}}>
        <h4>Correlation Data</h4>
        <DataTable
          value={monthsData}
          style={{width: '75vw',  }}
        >
          {columnsCorrelation.map(({header, field},prop) => (<Column key={prop} header={header} field={field} />))}
        </DataTable>
        <Button
          className="p-button-secondary p-button-outlined p-button-rounded"
          label="Analysis Data"
          onClick={()=>setAction("analysis")}
        />
        {action==="analysis" && actionDialog("analysis")}
      </div>
    </div>
  );
}

/* eslint-disable react/react-in-jsx-scope */
export default function Paper() {
  return (
    <div className="grid-container">
      <div className="grid-content">
        <h1>Paper</h1>
        <span>The methods are based on my <a href="https://doi.org/10.1115/GT2022-84236">paper</a> published the ASME Turbo Expo 2022: 
          Turbomachinery Technical Conference and Exposition Journal in June 2022.
        </span>


        <h3>Abstract</h3>
        <span>
          In times, when power generation exceeds demand or prices are low, excess energy can be stored for later use. The stored energy is used in the opposite case when power consumption exceeds generation.
          For an energy system where, renewable energy is targeted to be the main source of power generation on our way to carbon neutrality, it is important to understand how much energy needs to be stored to maximize the direct and domestic utilization of the generated energy and thus, to minimize the amount of imported and exported energy. The needed capacity of an energy storage device depends on how well the renewable power generation and consumption are aligned over time. Hence, a description of the alignment between the power generation of renewable energy sources and the power consumption is key to understand energy storage capacity needs.
          In this paper a simple method to describe power consumption / consumption alignment and how to derive storage capacity needs from this description is presented. The method can be applied to existing supply / demand data or to simulation results to reduce the simulation effort. The effects of capacity modifications to a power generation and storage system can be easily analyzed. The method can be widely applied to a single project or to an entire nation-wide energy-system, to analyze time periods from a day to a month or a year.
          The application of the method is shown by three examples: The first one is a photovoltaic system with a battery storage of different sizes and over different time periods. The second and third example is the renewable energy system of a whole country. The results of the method and the limits of the application of the method are discussed.
        </span>
      </div>
    </div>
  )
}

/* eslint-disable react/react-in-jsx-scope */
import { Routes, Route } from "react-router-dom";
import "primereact/resources/themes/saga-green/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
// import InputContext from "./elements/appContext";

import NavBar from "./elements/NavBar";
import About from "./pages/About";
import LandingPage from "./pages/LandingPage";
import Paper from "./pages/Paper";

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/paper" element={<Paper />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </>
  );
}

export default App;
